import { useHeader } from "../../hooks/headerHook";
import { useEffect, useState } from "react";
import { Layout, Spin } from "antd";
import { useLocation, useParams } from "react-router-dom";
import visualizerService from "../../services/visualizerService";
import { useCaseById } from "../../features/case/domain/hooks/useCaseById";
import { useSequencesByCase } from "../../features/case/domain/hooks/useSequenceByCase";
import {
    ISequence,
    ISequenceSliceController,
    ISequenceSliceReference,
    ISequencesData,
    IView,
} from "../../features/case/domain/entities/sequence";
import { useImagesByCase } from "../../features/case/domain/hooks/useImagesByCase";
import {
    ISequenceImages,
    IVisualizerWindow,
} from "../../features/case/domain/entities/modelImages";
import { VisualizerHeader } from "../../features/case/presentation/visualizerHeader/visualizerHeader";
import { VisualizerSider } from "../../features/case/presentation/visualizerSider/visualizerSider";
import { Visualizer } from "../../features/case/presentation/visualizerLayout/visualizer";
import { FinalDiagnosisModal } from "../../features/case/presentation/finalDiagnosisModal";
import {
    ICaseDetails,
    caseDetailsFromPartialCase,
} from "../../features/case/domain/entities/case";

interface IProps {
    isOrgAdmin?: boolean;
}

export const CaseVisualizerPage = ({ isOrgAdmin = false }: IProps) => {
    const { setHeaderContent } = useHeader();
    const { caseId } = useParams<any>();
    const location = useLocation();

    //To deny the access to set values in states
    const [isLoadingVisualizerWindows, setLoadingVisualizerWindows] =
        useState<boolean>(false);
    const [isFinalDiagnosisModalVisible, setIsFinalDiagnosisModalVisible] =
        useState<boolean>(false);
    //If the multiplanar view is active or not
    const [isMultiplanar, setMultiplanar] = useState<boolean>(false);
    //If the choosen image can be seen in multiplanar view
    const [allowMultiplanarView, setAllowMultiplanarView] =
        useState<boolean>(false);
    //If only has the axial view or not
    const [isMultiViewAvailable, setIsMultiViewAvailable] =
        useState<boolean>(false);
    const [isPixelAnalizeActive, setPixelAnalizeActive] =
        useState<boolean>(false);

    const visualizerWindowsInitialState = [
        {
            activeImageSrc: "",
            activeType: "",
            activeModel: "",
            activeView: "",
            activeBiomarker: "",
        },
        {
            activeImageSrc: "",
            activeType: "",
            activeModel: "",
            activeView: "",
            activeBiomarker: "",
        },
        {
            activeImageSrc: "",
            activeType: "",
            activeModel: "",
            activeView: "",
            activeBiomarker: "",
        },
        {
            activeImageSrc: "",
            activeType: "",
            activeModel: "",
            activeView: "",
            activeBiomarker: "",
        },
    ];
    const [visualizerWindows, setVisualizerWindows] = useState<
        IVisualizerWindow[]
    >(visualizerWindowsInitialState);
    const [sequenceSliceController, setSequenceSliceController] =
        useState<ISequenceSliceController>({});
    const [sequenceSliceReference, setSequenceSliceReference] =
        useState<ISequenceSliceReference>({
            type: "",
            activeSlice: 1,
            sliceCount: 1,
        });
    const [sequencesData, setSequencesData] = useState<ISequencesData>();
    const [caseData, setCaseData] = useState<ICaseDetails>();
    const [currentSequencesData, setCurrentSequencesData] =
        useState<ISequence[]>();
    const [currentSequenceImages, setCurrentSequenceImages] =
        useState<ISequenceImages>();

    const [activeWindow, setActiveWindow] = useState<number>(0);
    const [visualizerLayout, setVisualizerLayout] = useState<number>(1);

    const [singleImages, setSingleImages] = useState<Set<string>>(
        new Set<string>()
    );

    //===============HOOKS==================
    const {
        data: currentCase,
        isLoading: caseLoading,
        getCaseById,
    } = useCaseById();
    const { data: loadCurrentSequences, getSequencesByCase } =
        useSequencesByCase();
    const { data: loadCurrentImages, getImagesByCase } = useImagesByCase();

    //===============Functions==================
    const setSequenceSliceControllerFromCurrentSequence = (
        type: string,
        views: IView,
        singleImages: string[]
    ) => {
        sequenceSliceController[type] = sequenceSliceController[type] || {};
        if (!!views.coronal && !!views.sagital) {
            sequenceSliceController[type]["coronal"] = {
                activeSlice: 1,
                sliceCount: views.coronal,
            };

            sequenceSliceController[type]["sagital"] = {
                activeSlice: 1,
                sliceCount: views.sagital,
            };
            setIsMultiViewAvailable(true);
        }

        if (!!views.axial) {
            sequenceSliceController[type]["axial"] = {
                activeSlice: 1,
                sliceCount: views.axial,
            };
        }

        if (singleImages.length > 0)
            sequenceSliceController[type]["singleImages"] = {
                activeSlice: 1,
                sliceCount: 1,
            };

        setSequenceSliceController(sequenceSliceController);
    };

    const setVisualizerWindowByIndex = (
        index: number,
        value: IVisualizerWindow
    ) => {
        setVisualizerWindows((prevState) => {
            return prevState.map((obj, iterationIndex) => {
                if (iterationIndex === index) {
                    return value;
                }
                return obj;
            });
        });
    };

    const emptyVisualizerWindowByIndex = (index: number) => {
        setVisualizerWindows((prevState) => {
            return prevState.map((obj, iterationIndex) => {
                if (iterationIndex === index) {
                    return {
                        activeType: "",
                        activeImageSrc: "",
                        activeModel: "",
                        activeView: "",
                        activeBiomarker: "",
                    };
                }
                return obj;
            });
        });
    };

    const checkSliceLimits = (activeSlice: number, sliceCount: number) => {
        if (activeSlice > sliceCount + 1) return sliceCount + 1;
        else if (activeSlice < 1) return 1;
        return activeSlice;
    };

    const activeSliceByWindow = (isFromDifferentType: boolean) => {
        Object.keys(sequenceSliceController).forEach((sequence) => {
            if (sequenceSliceReference.sliceCount === 1 && sequenceSliceController[sequence]["singleImages"] !== undefined)
                sequenceSliceController[sequence]["singleImages"].activeSlice = 1;
            
            else if (visualizerLayout === 1 && isFromDifferentType)
                sequenceSliceController[sequence]["axial"].activeSlice = 1;
            
            
            //Sets the number of axial slice for each sequence respect to the reference sequence (the one with the greatest number of slices)
            else if (sequenceSliceReference.activeSlice === sequenceSliceReference.sliceCount + 1)
                sequenceSliceController[sequence]["axial"].activeSlice =
                    sequenceSliceController[sequence]["axial"].sliceCount + 1;

            else
                sequenceSliceController[sequence]["axial"].activeSlice =
                    Math.round(
                        sequenceSliceController[sequence]["axial"].sliceCount *
                            (sequenceSliceReference.activeSlice /
                                sequenceSliceReference.sliceCount)
                    );

            sequenceSliceController[sequence]["axial"].activeSlice =
                checkSliceLimits(
                    sequenceSliceController[sequence]["axial"].activeSlice,
                    sequenceSliceController[sequence]["axial"].sliceCount
                );

            if (isMultiViewAvailable && isMultiplanar) {
                sequenceSliceController[sequence]["axial"].activeSlice =
                    sequenceSliceReference.activeSlice;
                sequenceSliceController[sequence]["coronal"].activeSlice = 1;
                sequenceSliceController[sequence]["sagital"].activeSlice = 1;
                if (
                    sequenceSliceReference.activeSlice ===
                    sequenceSliceReference.sliceCount + 1
                ) {
                    sequenceSliceController[sequence]["coronal"].activeSlice =
                        sequenceSliceController[sequence]["coronal"]
                            .sliceCount + 1;
                    sequenceSliceController[sequence]["sagital"].activeSlice =
                        sequenceSliceController[sequence]["sagital"]
                            .sliceCount + 1;
                }
            }
        });

        setSequenceSliceController({ ...sequenceSliceController });
    };

    const setImageInScreen = (
        sequenceImages: ISequenceImages,
        index: number,
        activeType: string,
        activeModel: string,
        activeView: string,
        activeBiomarker: string,
        activeSlice: number
    ) => {
        if (activeModel === "" || activeBiomarker === "") return;
        if (singleImages?.has(activeBiomarker)) {
            setAllowMultiplanarView(false);
            setVisualizerWindowByIndex(index, {
                activeType: activeType || "",
                activeImageSrc:
                    sequenceImages[activeModel]["singleImages"][
                        activeBiomarker
                    ][0],
                activeModel: activeModel || "",
                activeView: "singleImages",
                activeBiomarker: activeBiomarker || "",
            });
        } else {
            setAllowMultiplanarView(true);
            setVisualizerWindowByIndex(index, {
                activeType: activeType || "",
                activeImageSrc:
                    sequenceImages[activeModel][activeView][activeBiomarker][
                        activeSlice - 1
                    ],
                activeModel: activeModel || "",
                activeView: activeView || "",
                activeBiomarker: activeBiomarker || "",
            });
        }
    };

    const getCookiesAndImages = async (caseId: string) => {
        await visualizerService.getCookies(caseId);
        await getImagesByCase(caseId);
    };

    //===============USEEFFECTS==================
    useEffect(() => {
        if (!caseId) return;
        setHeaderContent({
            title: "",
            breadCrumb: [],
        });
        getCaseById(caseId);
        if (location.state) setSequencesData(location.state as ISequencesData);
        else {
            getSequencesByCase(caseId!);
            getCookiesAndImages(caseId!);
        }
    }, [caseId]);

    useEffect(() => {
        if (!currentCase) return;
        setCaseData(caseDetailsFromPartialCase(currentCase));
    }, [currentCase]);

    useEffect(() => {
        if (!loadCurrentSequences) return;
        setCurrentSequencesData(loadCurrentSequences);
    }, [loadCurrentSequences]);

    useEffect(() => {
        if (!loadCurrentImages) return;
        setCurrentSequenceImages(loadCurrentImages);
    }, [loadCurrentImages]);

    useEffect(() => {
        if (!currentSequencesData || currentSequencesData.length === 0) return;
        let singleImages = new Set<string>();
        currentSequencesData.forEach((sequence) => {
            sequence.singleImages.forEach((singleImage) => {
                singleImages.add(singleImage);
            });
            setSequenceSliceControllerFromCurrentSequence(
                sequence.type,
                sequence.views,
                sequence.singleImages
            );
        });
        setSingleImages(singleImages);
    }, [currentSequencesData]);

    useEffect(() => {
        if (!sequencesData) return;
        setCurrentSequencesData(sequencesData.sequences);
        setCurrentSequenceImages(sequencesData.images);
        setLoadingVisualizerWindows(true);
    }, [sequencesData]);

    useEffect(() => {
        if (!currentSequenceImages) return;
        if (sequencesData?.biomarkerTag!) {
            setImageInScreen(
                currentSequenceImages,
                0,
                sequencesData!.biomarkerTag.sequenceType.toUpperCase(),
                sequencesData!.biomarkerTag.sequenceModel,
                "axial",
                sequencesData!.biomarkerTag.biomarker,
                0
            );
            setAllowMultiplanarView(true);
            setActiveWindow(0);
            setSequenceSliceReference({
                ...sequenceSliceReference,
                type: sequencesData!.biomarkerTag.sequenceType.toUpperCase(),
                activeSlice: sequencesData!.biomarkerTag.views.axial! + 1,
                sliceCount: sequencesData!.biomarkerTag.views.axial!,
            });
        }
        // Preload images
        Object.keys(currentSequenceImages).forEach((model) => {
            Object.keys(currentSequenceImages[model]["axial"]).forEach(
                (bio) => {
                    currentSequenceImages[model]["axial"][bio].forEach(
                        (img) => {
                            new Image().src = img;
                        }
                    );
                }
            );
        });
    }, [currentSequenceImages]);

    useEffect(() => {
        if (isLoadingVisualizerWindows) {
            setLoadingVisualizerWindows(false);
            return;
        }
        if (!currentSequenceImages || !sequenceSliceController) return;
        if (isMultiplanar) {
            setImageInScreen(
                currentSequenceImages,
                0,
                visualizerWindows[0].activeType,
                visualizerWindows[0].activeModel,
                "axial",
                visualizerWindows[0].activeBiomarker,
                sequenceSliceController[visualizerWindows[0].activeType][
                    "axial"
                ].activeSlice
            );
            setImageInScreen(
                currentSequenceImages,
                2,
                visualizerWindows[2].activeType,
                visualizerWindows[2].activeModel,
                "coronal",
                visualizerWindows[2].activeBiomarker,
                sequenceSliceController[visualizerWindows[2].activeType][
                    "coronal"
                ].activeSlice
            );
            setImageInScreen(
                currentSequenceImages,
                3,
                visualizerWindows[3].activeType,
                visualizerWindows[0].activeModel,
                "sagital",
                visualizerWindows[3].activeBiomarker,
                sequenceSliceController[visualizerWindows[3].activeType][
                    "sagital"
                ].activeSlice
            );
            return;
        }
        visualizerWindows.forEach((window, index) => {
            if (window.activeImageSrc === "") return;
            setImageInScreen(
                currentSequenceImages,
                index,
                window.activeType,
                window.activeModel,
                window.activeView,
                window.activeBiomarker,
                sequenceSliceController[window.activeType][window.activeView]
                    .activeSlice
            );
        });
    }, [sequenceSliceController, currentSequenceImages]);

    useEffect(() => {
        if (isLoadingVisualizerWindows) {
            setLoadingVisualizerWindows(false);
            return;
        }
        if (!currentSequenceImages || !sequenceSliceController) return;
        visualizerWindows.forEach((window, index) => {
            if (window.activeImageSrc === "") return;
            emptyVisualizerWindowByIndex(index);
        });

        if (isMultiplanar) {
            setImageInScreen(
                currentSequenceImages,
                0,
                visualizerWindows[activeWindow].activeType,
                visualizerWindows[activeWindow].activeModel,
                "axial",
                visualizerWindows[activeWindow].activeBiomarker,
                sequenceSliceController[
                    visualizerWindows[activeWindow].activeType
                ]["axial"].activeSlice
            );
            setImageInScreen(
                currentSequenceImages,
                2,
                visualizerWindows[activeWindow].activeType,
                visualizerWindows[activeWindow].activeModel,
                "coronal",
                visualizerWindows[activeWindow].activeBiomarker,
                sequenceSliceController[
                    visualizerWindows[activeWindow].activeType
                ]["coronal"].activeSlice
            );
            setImageInScreen(
                currentSequenceImages,
                3,
                visualizerWindows[activeWindow].activeType,
                visualizerWindows[activeWindow].activeModel,
                "sagital",
                visualizerWindows[activeWindow].activeBiomarker,
                sequenceSliceController[
                    visualizerWindows[activeWindow].activeType
                ]["sagital"].activeSlice
            );
            setSequenceSliceReference({
                type: visualizerWindows[activeWindow].activeType,
                activeSlice:
                    sequenceSliceController[visualizerWindows[0].activeType][
                        "axial"
                    ].activeSlice,
                sliceCount:
                    sequenceSliceController[visualizerWindows[0].activeType][
                        "axial"
                    ].sliceCount,
            });
        } else {
            setVisualizerLayout(1);
            setSequenceSliceReference({
                ...sequenceSliceReference,
                activeSlice:
                    sequenceSliceController[visualizerWindows[0].activeType][
                        "axial"
                    ].activeSlice,
            });
            setImageInScreen(
                currentSequenceImages,
                0,
                visualizerWindows[0].activeType,
                visualizerWindows[0].activeModel,
                "axial",
                visualizerWindows[0].activeBiomarker,
                sequenceSliceController[visualizerWindows[0].activeType][
                    "axial"
                ].activeSlice
            );
            setActiveWindow(0);
        }
    }, [isMultiplanar]);

    useEffect(() => {
        if (
            visualizerWindows[activeWindow].activeImageSrc === "" ||
            singleImages.has(visualizerWindows[activeWindow].activeBiomarker)
        ) {
            setAllowMultiplanarView(false);
            return;
        }
        setAllowMultiplanarView(true);
    }, [activeWindow]);

    useEffect(() => {
        let maxSliceCount = 1;
        let activeSlice = 1;
        let type = "";
        visualizerWindows.forEach((window) => {
            if (
                window.activeType !== "" &&
                (window.activeView === "axial" ||
                    singleImages.has(window.activeView))
            )
                if (
                    sequenceSliceController[window.activeType][
                        window.activeView
                    ].sliceCount >= maxSliceCount
                ) {
                    maxSliceCount =
                        sequenceSliceController[window.activeType][
                            window.activeView
                        ].sliceCount;
                    type = window.activeType;
                    activeSlice =
                        sequenceSliceController[window.activeType][
                            window.activeView
                        ].activeSlice;
                }
        });
        if (maxSliceCount !== sequenceSliceReference.sliceCount)
            setSequenceSliceReference({
                type,
                activeSlice,
                sliceCount: maxSliceCount,
            });
    }, [visualizerWindows]);

    useEffect(() => {
        activeSliceByWindow(false);
    }, [sequenceSliceReference]);

    return (
        <>
            <Spin spinning={caseLoading}>
                <Layout>
                    <VisualizerHeader
                        caseId={caseId!}
                        currentCase={caseData}
                        isMultiplanar={isMultiplanar}
                        setVisualizerLayout={setVisualizerLayout}
                        emptyVisualizerWindowByIndex={
                            emptyVisualizerWindowByIndex
                        }
                        activeWindow={activeWindow}
                        setActiveWindow={setActiveWindow}
                        setActiveMultiplanar={setMultiplanar}
                        allowMultiplanarView={allowMultiplanarView}
                        visualizerWindows={visualizerWindows}
                        singleImages={singleImages}
                        isMultiViewAvailable={isMultiViewAvailable}
                        isPixelAnalizeActive={isPixelAnalizeActive}
                        setPixelAnalizeActive={setPixelAnalizeActive}
                        isFinalDiagnosisModalVisible={
                            isFinalDiagnosisModalVisible
                        }
                        setIsFinalDiagnosisModalVisible={
                            setIsFinalDiagnosisModalVisible
                        }
                        sequenceSliceReference={sequenceSliceReference}
                        setSequenceSliceReference={setSequenceSliceReference}
                        sequenceSliceController={sequenceSliceController}
                        setSequenceSliceController={setSequenceSliceController}
                        isOrgAdmin={isOrgAdmin}
                    />
                    <Layout>
                        <VisualizerSider
                            currentSequencesData={currentSequencesData}
                            visualizerWindows={visualizerWindows}
                            sequenceImages={currentSequenceImages!}
                            activeWindow={activeWindow}
                            singleImages={singleImages}
                            isMultiplanar={isMultiplanar}
                            setImageInScreen={setImageInScreen}
                            sequenceSliceController={sequenceSliceController}
                            setSequenceSliceReference={
                                setSequenceSliceReference
                            }
                            activeSliceByWindow={activeSliceByWindow}
                        />
                        <Visualizer
                            visualizerLayout={visualizerLayout}
                            visualizerWindows={visualizerWindows}
                            setActiveWindow={setActiveWindow}
                            activeWindow={activeWindow}
                            singleImages={singleImages}
                            isMultiplanar={isMultiplanar}
                            isPixelAnalizeActive={isPixelAnalizeActive}
                            sequenceSliceController={sequenceSliceController}
                            setSequenceSliceController={
                                setSequenceSliceController
                            }
                            sequenceSliceReference={sequenceSliceReference}
                            setSequenceSliceReference={
                                setSequenceSliceReference
                            }
                        />
                    </Layout>
                </Layout>
                <FinalDiagnosisModal
                    isVisible={isFinalDiagnosisModalVisible}
                    setIsVisible={setIsFinalDiagnosisModalVisible}
                    caseId={caseId}
                    caseDetails={caseData!}
                    setCaseDetails={setCaseData!}
                />
            </Spin>
        </>
    );
};
